<template>
  <b-modal ref="modal" :title="product.name" @ok="save" :ok-disabled="loading">
    <b-form-group label="Chargepoint number">
      <b-input v-model="product.chargepoint_number"></b-input>
    </b-form-group>
    <b-alert v-if="error" variant="danger" show>
      {{ error }}
    </b-alert>
    <template #modal-ok>
      <b-spinner v-if="loading" small></b-spinner>
      Save
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      id: null,
      product: {
        name: '',
        chargepoint_number: '',
      },
      loading: false,
      error: null,
    };
  },
  methods: {
    open(product, jp) {
      this.product = {
        ...product,
      };
      this.id = jp.id;
      this.$refs.modal.show();
    },
    async save(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.loading = true;
      this.error = null;
      try {
        await axios.patch(`/data-api/job-product/${this.id}`, {
          chargepointNumber: this.product.chargepoint_number,
        });
        this.$refs.modal.hide();
        this.$emit('updated', this.product);
      } catch (error) {
        console.error(error);
        this.error = error.toString();
      }
      this.loading = false;
    },
  },
};
</script>
