<template>
  <div>
    <h4>{{ $t('jobs.products-by-supplier') }}:</h4>
    <div
      v-for="{ id, jobApiId, name, products } of productsBySupplier"
      :key="id"
      class="ml-1"
    >
      <h5 class="mt-3 mb-2">{{ name }}</h5>

      <template v-if="products.length">
        <ul
          v-for="(product, index) of products"
          :key="index"
          class="bg-transparent list-unstyled"
        >
          <li class="d-flex align-items-baseline">
            <span class="mr-1">{{ product.quantity }}&nbsp;&times;</span>
            <div>
              <span>{{ product.name }}</span>
              <span
                v-if="!getCurrentProductOrder(jobApiId, id)"
                v-b-tooltip.hover
                :title="$t('products-order.missing-tracking-info')"
              >
                <BIconExclamationTriangle
                  class="ml-2"
                  style="font-size: 1.7rem"
                  variant="danger"
                />
              </span>
              <div v-if="product.serial_number">
                SN: {{ product.serial_number }}
                <template
                  v-if="
                    product.chargepoint_number || chargepointRequired(product)
                  "
                >
                  | Chargepoint:
                  <template v-if="product.chargepoint_number">
                    {{ product.chargepoint_number }}
                    <b-button
                      size="sm"
                      title="Edit"
                      class="align-baseline"
                      v-b-tooltip.hover
                      @click="editChargepoint(product)"
                    >
                      <BIconPencil />
                    </b-button>
                  </template>
                  <b-button
                    v-else
                    variant="warning"
                    size="sm"
                    class="align-baseline"
                    @click="editChargepoint(product)"
                  >
                    Enter
                  </b-button>
                </template>
              </div>
            </div>
          </li>
        </ul>
      </template>
      <template v-if="getCurrentProductOrder(jobApiId, id)">
        <div class="mb-1">
          <b-icon-box class="mr-2" />
          <a :href="getCurrentProductOrder(jobApiId, id)?.url" target="_blank">
            {{ $t('products-order.tracking-link') }}
          </a>
        </div>
        <div class="mb-2">
          {{ $t('products-order.shipping-to') }}:
          <b-badge variant="secondary">
            {{
              $t(
                `products-order.${
                  getCurrentProductOrder(jobApiId, id)?.shipTo
                }`,
              )
            }}
          </b-badge>
        </div>
      </template>

      <b-button
        v-if="canUpdateTrackingID"
        :variant="getCurrentProductOrder(jobApiId, id) ? 'primary' : 'danger'"
        class="mb-4"
        @click="$emit('toggleModal', { id, jobApiId, name, products })"
      >
        {{
          $t(
            `products-order.${
              getCurrentProductOrder(jobApiId, id) ? 'update' : 'add'
            }-tracking-info`,
          )
        }}
      </b-button>
    </div>
    <ChargepointModal ref="chargepointModal" @updated="chargepointUpdated" />
  </div>
</template>

<script>
import { BIconBox, BIconExclamationTriangle, BIconPencil } from 'bootstrap-vue';
import { JobStatusEnum } from '@gid/models';
import { StatusRulesNamesEnum } from '@gid/models/dist/entities/status-rules-names-enum';
import axios from 'axios';
import ChargepointModal from './ChargepointModal.vue';

export default {
  components: {
    BIconBox,
    BIconExclamationTriangle,
    BIconPencil,
    ChargepointModal,
  },
  props: {
    item: Object,
    getCurrentProductOrder: Function,
    toggleModal: Function,
  },
  data() {
    return {
      jobProductsRequiringChargepoint: [],
    };
  },
  computed: {
    canUpdateTrackingID() {
      return (
        this.item?.job?.states?.list.includes(
          `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`,
        ) ||
        this.item?.jobs?.some((job) =>
          job.job.states?.includes(
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`,
          ),
        )
      );
    },
    productsBySupplier() {
      const bySupplier = {};

      this.item.products.forEach((product) => {
        const { supplier_sfid, supplier_name, job_api_id } = product;

        if (!bySupplier[supplier_sfid]) {
          bySupplier[supplier_sfid] = {
            id: supplier_sfid,
            jobApiId: job_api_id,
            name: supplier_name,
            products: [],
          };
        }

        bySupplier[supplier_sfid].products.push(product);
      });

      return Object.values(bySupplier);
    },
  },
  async created() {
    if (
      this.item.job.states?.list.includes(
        `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CHARGEPOINT_REQUIRED}.missing`,
      ) ||
      this.item.products.some((p) => p.chargepoint_number)
    ) {
      const { data: rulesDetails } = await axios.get(
        `/jobs-api/status-rules/job/${this.item.job.api_id}/rules-details`,
      );
      this.jobProductsRequiringChargepoint =
        rulesDetails[StatusRulesNamesEnum.CHARGEPOINT_REQUIRED]
          ?.jobProductsRequiringChargepoint ?? [];
    }
  },
  methods: {
    chargepointRequired(jp) {
      return this.jobProductsRequiringChargepoint.find(
        (item) => item.productId == jp.id,
      );
    },
    editChargepoint(product) {
      this.$refs.chargepointModal.open(
        product,
        this.chargepointRequired(product),
      );
    },
    chargepointUpdated(product) {
      this.item.products.find((p) => p.id == product.id).chargepoint_number =
        product.chargepoint_number;
      setTimeout(() => {
        this.$emit('refresh');
      }, 2000);
    },
  },
};
</script>
