<template>
  <div>
    <h4>
      {{ $t('jobs.table.heading.opportunity') }}:
      <span class="font-weight-light">{{ item.opportunity.name }}</span>
    </h4>
    <b-row>
      <b-col md="6 mt-2">
        <ProductsList
          :item="item"
          :getCurrentProductOrder="getCurrentProductOrder"
          @toggleModal="toggleModal"
          @refresh="$emit('refresh')"
        />
        <h4>{{ $t('jobs.services') }}:</h4>
        <table class="table table-borderless table-sm">
          <tbody>
            <tr
              v-for="(service, index) in allServices"
              :key="index"
              class="bg-transparent"
            >
              <div
                v-if="service.headline"
                class="font-weight-bold"
                style="padding-left: 0.3rem"
              >
                {{ service.headline }}
              </div>
              <div>
                <td>{{ service.quantity }}&nbsp;&times;</td>
                <td>
                  {{
                    service.description[locale] ||
                    service.description.en ||
                    service.description.de
                  }}
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col md="6">
        <template v-if="user && customerDetailsIds.includes(user.account.id)">
          <h4>{{ $t('jobs.customer.details') }}:</h4>
          <p class="w-100" v-if="getCustomerDetails.name">
            <strong>{{ $t('jobs.customer.name') }}:</strong>
            {{ getCustomerDetails.name }}
          </p>
          <div class="w-100">
            <strong>{{ $t('jobs.customer.address') }}:</strong>
            <p v-if="getCustomerDetails.address.street">
              {{ getCustomerDetails.address.street }}
            </p>
            <p>
              <span v-if="getCustomerDetails.address.postal_code">
                {{ getCustomerDetails.address.postal_code }}
              </span>
              <span v-if="getCustomerDetails.address.city">
                {{ getCustomerDetails.address.city }}
              </span>
            </p>
          </div>
          <p class="w-100" v-if="getCustomerDetails.email">
            <strong>{{ $t('jobs.customer.email') }}:</strong>
            {{ getCustomerDetails.email }}
          </p>
          <p class="w-100" v-if="getCustomerDetails.phone">
            <strong>{{ $t('jobs.customer.phone') }}:</strong>
            {{ getCustomerDetails.phone }}
          </p>
        </template>
        <ActivityFeed ref="activityFeed" class="mb-4" :job="item" />
        <b-button
          variant="primary"
          @click="$refs.activityFeed.addActivity('comment')"
        >
          {{ $t('comments.new') }}
        </b-button>
        <template v-if="!!item.job.professional_comment">
          <h4 class="mt-5">
            {{ $t('inputs.headings.professional_comment') }}:
          </h4>
          <b-form-textarea
            :value="item.job.professional_comment"
            rows="3"
            max-rows="6"
            :readonly="true"
          />
        </template>
      </b-col>
      <b-col md="4" v-if="showInvoicing">
        <h4>{{ $t('jobs.invoices.heading') }}:</h4>
        <b-spinner v-if="loadingInvoices" label="Loading" />
        <div v-else-if="!invoices.length">-</div>
        <div v-else>
          <b-form-row>
            <b-col class="text-left">
              <em>{{ $t('jobs.invoices.status') }}</em>
            </b-col>
            <b-col class="text-left">
              <em>{{ $t('jobs.invoices.date') }}</em>
            </b-col>
            <b-col class="text-left">
              <em>{{ $t('jobs.invoices.invoice') }}</em>
            </b-col>
          </b-form-row>
          <b-form-row v-for="(invoiceView, idx) in invoices" :key="idx">
            <b-col class="text-left">
              <strong v-if="invoiceView.invoice.status">
                {{ $t(`invoice-statuses.${invoiceView.invoice.status}`) }}
              </strong>
              <span v-else>-</span>
            </b-col>
            <b-col class="text-left">
              <strong v-if="invoiceView.invoice.date">
                {{ invoiceView.invoice.date }}
              </strong>
              <span v-else>-</span>
            </b-col>
            <b-col class="text-left">
              <a
                :href="`/api/invoice/download/${invoiceView.invoice.path.replace(
                  'documents-api/file',
                  '',
                )}?jwt=${access_token}`"
                target="blank"
              >
                <strong>{{ invoiceView.name }}</strong>
              </a>
            </b-col>
          </b-form-row>
        </div>
      </b-col>
    </b-row>
    <UpdateInfoModal
      ref="updateInfoModal"
      :activeProductsGroup="activeProductsGroup"
      @callback="confirmOrder"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ActivityFeed from '@gid/vue-common/components/ActivityFeed';
import productOrdersMixin from '@/mixins/ProductOrdersMixin';

export default {
  mixins: [productOrdersMixin({ isProjectsPage: false })],
  components: {
    ActivityFeed,
  },
  props: {
    item: Object,
  },
  async created() {
    if (this.showInvoicing) {
      this.loadInvoices();
    }
  },
  data() {
    return {
      loadingInvoices: null,
      invoices: [],
      notificationsEnabled: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'locale', 'contactProfiles', 'access_token']),

    customerDetailsIds() {
      return [
        '0011p00002jGnUBAA0', //gardena france
        '0010Y00000kkXieQAE', // weber germany
      ];
    },
    allServices() {
      return [
        ...this.item.services,
        ...this.item.custom_services.map((service) => ({
          ...service,
          description: {
            de: service.name,
            en: service.name,
          },
        })),
      ];
    },
    getCustomerDetails() {
      const name =
        this.item?.customer.first_name + ' ' + this.item?.customer.last_name;
      const email = this.item?.customer?.email;
      const phone = this.item?.customer?.phone;
      const address = this.item?.customer?.shipping_address;
      return { name, email, phone, address };
    },
    accessToken() {
      return this.access_token;
    },
    showInvoicing() {
      const contactProfilesAllowed = ['accounting', 'admin'];
      return this.$store.getters.contactProfiles.some((p) =>
        contactProfilesAllowed.includes(p),
      );
    },
    checkProductDetails() {
      return this.productOrders?.length;
    },
  },
  methods: {
    loadInvoices() {
      (this.loadingInvoices = true),
        axios
          .get(`/api/brand/invoices/for-job/${this.item.job.sfid}`)
          .then((response) => {
            this.loadingInvoices = false;
            this.invoices = response.data.data.sort((a, b) =>
              a.invoice.date.localeCompare(b.invoice.date),
            );
          });
    },
    invoiceUrl(invoiceId) {
      return `/api/invoice/download/${invoiceId}?jwt=${this.accessToken}`;
    },
  },
};
</script>
